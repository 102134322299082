import React from 'react'
import CheckValue from '../CheckValue'
import { useSelector } from 'react-redux'
import imgPlan from '../../img/vector/icon_corporative.svg'
import bannerLanding from '../../img/brand/banner_corporative.png'
import Slider from 'react-slick'
import FooterPlans from '../FooterPlans'
import Payment from '../Payment'

const PlansCorporative = () => {

  const settings = {
    dots: true,
    arrows: false,
    slidesToShow: 1,
    autoplay: true,
    infinite: true,
    speed: 500,
    className: 'slideBenefits mb-0 mb-md-5'
  }

  const {days} = useSelector(state => state.landing)

  


  return (
    <>
      <div className="headerPlan mb-4">
        <img src={imgPlan} alt="Landingpage" className='img-fluid'/>
        <h4 className="m-0 text-aqua text-bold">Página corporativa</h4>
      </div>  
      <div className="col-md-5">
        <img src={bannerLanding} alt="Landingpage" className='img-fluid w-100 mb-3'/>
        <div className="row mb-3">
          <div className="col-6 text-center">
            <small className="text-aqua">Tiempo estimado</small>
            <p className="m-0">{days} días hábiles</p>
          </div>
          <div className="col-6 text-center">
            <small className="text-aqua">Abono</small>
            <p className="m-0">50% inicial - 50% en la entrega</p>
          </div>
        </div>
        <Slider {...settings}>
          <div className="listInclude">
            <span className="text-aqua">Presencia profesional</span>
          </div>
          <div className="listInclude">
            <span className="text-aqua">Credibilidad aumentada</span>
          </div>
          <div className="listInclude">
            <span className="text-aqua">Información centralizada</span>
          </div>
          <div className="listInclude">
            <span className="text-aqua">Contacto directo</span>
          </div>
        </Slider>
        <Payment />
      </div>
      <div className="col-md-7">
        <div className="row justify-content-center">
          <div className="col-md-10 pt-5 pt-md-3">
            <div className="text-aqua mb-3">¿Quieres que tu marca brille más que un meme viral?</div>
            <p>Con una Página Corporativa desde $599, damos a tu negocio un espacio web que grita profesionalismo, innovación y carisma. Porque tu empresa merece ser la estrella de Internet.</p>
            <div className="text-aqua mb-3">¿Qué ofrecemos?</div>
            <ul className='listPlans'>
              <li>Hosting + dominio .com por un año</li>
              <li>Certificado SSL (https)</li>
              <li>20 Cuentas de correo corporativo</li>
              <li>Administrador de sitios web Wordpress + Elementor Pro</li>
              <li>20 GB de espacio</li>
              <li>Link de redes sociales</li>
              <li>Botón flotante de WhatsApp</li>
              <li>Tutoría vía Zoom de 40 minutos  para la gestión de contenido de tu sitio</li>
              <li>Página de Inicio - Nosotros - Servicios - Contacto - Página de agradecimiento - Error 404 - Página de búsqueda</li>
            </ul>
            <div className="text-main my-3">¿Algo adicional?</div>
            <CheckValue
              id='C1'
              text='Galería de fotos'
              price={20}
              day={1}
            />
            <CheckValue
              id='C2'
              text='Blog (Subimos 1 publicación como modelo)'
              price={50}
              day={2}
            />
            <CheckValue
              id='C3'
              text='Brochure digital de 6 páginas enlazado a tu sitio'
              price={60}
              day={2}
            />
            <CheckValue
              id='C4'
              text='Plataforma de Email Marketing (hasta 1.000 envíos por mes) + tutoría de 30 minutos'
              price={80}
              day={2}
            />
            <CheckValue
              id='C5'
              text='Página adicional de Noticias o Novedades'
              price={50}
              day={1}
            />
            <CheckValue
              id='C6'
              text='Creación de plantilla personalizada para correos desde la página de contacto'
              price={30}
              day={1}
            />
            
            <FooterPlans />
            </div>
        </div>
      </div>
    </>
  )
}

export default PlansCorporative