import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsForm } from "../redux/slices/plans";

const FooterPlans = () => {
  const { isForm, amount } = useSelector((state) => state.landing);
  const dispatch = useDispatch();
  const changeForm = () => dispatch(setIsForm(!isForm));


  function formatCurrencyUSD(number) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });
  
    return formatter.format(number);
  }

  return (
    <>
      <div className="col-12 footerSection mt-5">
        <div className="row justify-content-center">
          <div className={`col-6 ${isForm ? 'd-none': ''}`}>
            <div className="totalPrice">
              <span>Total a pagar</span>
              <strong>{formatCurrencyUSD(amount)} USD</strong>
            </div>
          </div>
          <div className={isForm ? 'col-md-4': 'col-6'}>
            <button
              className={isForm ? "btnLine arrowBack" : "btnAqua"}
              onClick={changeForm}
            >
              {isForm ? 'Seguir editando': 'Confirmar'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterPlans;
