import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import emailjs from '@emailjs/browser'
import SendEmail from "../components/SendEmail";


const MainForm = () => {
  const contactForm = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [confeti, setConfeti] = useState(false)
  const [serverError, setServerError] = useState(false)
  const {register, formState:{errors}, handleSubmit} = useForm()
  const {includes, days, namePlan, amount} = useSelector(state => state.landing)
  
  const onSubmit = data =>{
    setIsLoading(true)
    setServerError(false)
    emailjs.sendForm('service_ugb0271', 'template_5kpsxko', contactForm.current, '65lVmP8V3QMotkHpi')
      .then((result) => {
        setIsLoading(true)
        setConfeti(true)
      }, (error) => {
        setServerError(true)
      });
  }
  

  const handleWhatsAppMessage = (includes, days, namePlan, amount) =>{
    let num = 'https://api.whatsapp.com/send?phone=573059260031&text='
    let init = 'Hola! estoy interesad@ en un servicio web con las siguientes características:'
    let type = `${namePlan} por un total de $${amount} con una duración de ${days} días`;
    const requiredments = includes.map(item => item.text).join(', ')
    let resume = `${init}${type}, ${includes.length === 0 ? 'Usaré el servicio base' : 'incluye:'} ${requiredments}. Gracias!`
    let encoding = encodeURIComponent(resume)
    return num+encoding
  }

  const convertList = list =>{
    let res = list.map(item => item.text).join(', ')
    return `"${res}"`;
  }
  

 
  return (
    <>
      {
        confeti && <SendEmail />
      }
      <form onSubmit={handleSubmit(onSubmit)} ref={contactForm}>
        <div className="col-12">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="text-aqua text-center mb-4 pb-md-5">
                Datos personales para el envío
              </div>
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-6">
                  <div className={`inputStyle ${isLoading ? 'loading': ''}`}>
                    <input
                      type="text"
                      placeholder="Nombre Completo*"
                      name="name"
                      {...register('name',{
                        required: {value: true, message: 'Nombre requerido'},
                        maxLength: {value: 30, message: 'Nombre demasiado largo'},
                        minLength: {value: 3, message: 'Nombre demasiado corto'},
                      })}
                      />
                    {errors.name && <small className='error'>{errors.name.message}</small> }
                  </div>
                  <input type="hidden" name='includes'  value={convertList(includes)} {...register('includes',{required:false})}/>
                  <input type="hidden" name='days'  value={days} {...register('days',{required:false})}/>
                  <input type="hidden" name='amount'  value={amount} {...register('amount',{required:false})}/>
                  <input type="hidden" name='namePlan'  value={namePlan} {...register('namePlan',{required:false})}/>
                </div>
                <div className="col-md-6">
                  <div className={`inputStyle ${isLoading ? 'loading': ''}`}>
                    <input type="mail"
                    placeholder="Correo electrónico*"
                    name="mail"
                    {...register('mail',{
                      required: {value: true, message: 'Necesitamos tu email'},
                      pattern: {value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 
                        message: 'Ingresa un correo válido'
                    }
                      
                    })}
                    />
                    {errors.mail && <small className='error'>{errors.mail.message}</small> }
                  </div>
                </div>
                <div className="col-md-6">
                  <div className={`inputStyle ${isLoading ? 'loading': ''}`}>
                    <input
                      type="tel"
                      placeholder="Celular*"
                      name="celphone"
                      {...register('celphone',{
                        required: {value: true, message: 'Campo requerido'},
                        pattern: {value: /^\d{10}$/, message: 'Ingresa un número válido'}
                      })}
                    />
                    {errors.celphone && <small className='error'>{errors.celphone.message}</small> }
                  </div>
                </div>
                
                <div className="col-md-6 mt-4 mt-md-0">
                  {
                    isLoading ? 
                      <div className="loadingEffect"><span /></div>
                      :
                      <button type="submit" className="btnAqua">
                        Solicitar servicio
                      </button>
                  }
                </div>
                {
                  serverError && (
                    <>
                      <div className="col-12">
                        <p className="error text-center">Ha ocurrido un error de conexión, inténtalo más tarde</p>
                      </div>
                    </>
                  )
                }
                <div className="col-12">
                  <div className="row justify-content-center">
                    <div className="col-md-12">
                      <div className="wspLink">
                        <p className="m-0">
                          Prefiero enviar esta cotización a través de
                          <a href={handleWhatsAppMessage(includes, days, namePlan, amount)} target='_blank' rel="noreferrer">WhatsApp :)</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default MainForm;
