import React from 'react'
import Matrix from '../components/Matrix'
import { Link } from 'react-router-dom'

const ErrorPage = () => {
  return (
    <>
      <Matrix />
      <section className="errorPage">
        <div>
          <h1 className="dlsiplay-1 text-line-aqua">ERROR 404</h1>
          <p>Página no encontrada</p>
          <Link to='/' className='btnAqua'>Volver al inicio</Link>  
        </div>
      </section>
    </>
  )
}

export default ErrorPage