import { createSlice } from "@reduxjs/toolkit"





export const counterSlice = createSlice({
  name: "landing",
  initialState: {
    active: false,
    type: 0, // 0:landing // 1: corporativa // 2:tienda virtual // 3: implementación AI // 4: aplicación // 5. gestión de contenido
    isForm: false,
    namePlan: null,
    amount: 0,
    days: 0,
    includes: []
  },
  reducers: {
    setSwitchSlice: (state, action) => {
      state.active  = action.payload
    },
    setTypeService: (state, action) => {
      state.type  = action.payload
    },
    setIsForm: (state, action) => {
      state.isForm  = action.payload
    },
    setNamePlan: (state, action) => {
      state.namePlan  = action.payload
    },
    setAmount: (state, action) => {
      state.amount  = action.payload
    },
    setAddAmount: (state, action) => {
      state.amount += action.payload
    },
    setDeleteAmount: (state, action) => {
      state.amount -= action.payload
    },
    setResetIncludes: state => {
      state.includes = []
    },
    setAddIncludes: (state, action) => {
      state.includes.push(action.payload)
    },
    setDeleteOneIncludes: (state, action) => {
      if(state.includes.length === 1){
        state.includes = []
      }else{
        let ref = state.includes.findIndex(el => el.id === action.payload)
        state.includes.splice(ref,1)
      }
    },
    resetDays: (state) => {
      state.days = 0  
    } 
    ,
    setAddDays: (state,action) =>{
      state.days += action.payload
    },
    setDeleteDays: (state,action) =>{
      state.days -= action.payload
    },
  },
})


export const {
  setSwitchSlice,
  setTypeService,
  setIsForm,
  setNamePlan,
  setAmount,
  setResetIncludes,
  setAddIncludes,
  setDeleteOneIncludes,
  setAddDays,
  setAddAmount,
  setDeleteAmount,
  setDeleteDays,
  resetDays,
} = counterSlice.actions

export default counterSlice.reducer


export const initAmount = (type) => dispatch =>{
  switch (true) {
    case type === 0:
      dispatch(setAmount(1100000))
      dispatch(setAddDays(4))
      break;
    case type === 1:
      dispatch(setAmount(1850000))
      dispatch(setAddDays(8))
      break;
    case type === 2:
      dispatch(setAmount(3850000))
      dispatch(setAddDays(15))
      break;
    case type === 3:
      dispatch(setAmount(2450000))
      dispatch(setAddDays(7))
      break;
    case type === 4:
      dispatch(setAmount(3450000))
      dispatch(setAddDays(30))
      break;
    case type === 5:
      dispatch(setAmount(777))
      dispatch(setAddDays(30))
      break;
    default:
      return false
  }
  dispatch(setResetIncludes())
}


export const evalAmount = (text,price,id,selected, day) => dispatch =>{
  if(selected){
    let obj = {id,text}
    dispatch(setAddIncludes(obj)) // agregar listado de solicitudes adicionales
    dispatch(setAddAmount(price)) // sumar precio
    dispatch(setAddDays(day)) // sumar día
  }else{
    dispatch(setDeleteOneIncludes(id)) // quitar listado de solicitudes adicionales
    dispatch(setDeleteAmount(price)) // restar precio
    dispatch(setDeleteDays(day)) // restar día
  }
}
