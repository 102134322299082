import React, { useEffect, useRef } from 'react'

const MoveLabel = ({text}) => {

  const movingX = useRef(null)

  useEffect(()=>{

    window.addEventListener('scroll', e =>{
      if(movingX.current){
        movingX.current.scrollLeft = (window.scrollY/3)
      }
    })

  })

  return (
    <>
      <div className="moveLabel" ref={movingX} >
        <span>{text ||'NA'}</span>
      </div>
    </>
  )
}

export default MoveLabel