import React from 'react'
import CheckValue from '../CheckValue'
import Slider from 'react-slick'
import { useSelector } from 'react-redux'
import imgPlan from '../../img/vector/icon_ecommerce.svg'
import bannerLanding from '../../img/brand/banner_store.png'
import FooterPlans from '../FooterPlans'
import Payment from '../Payment'

const PlanStore = () => {
  const settings = {
    dots: true,
    arrows: false,
    slidesToShow: 1,
    autoplay: true,
    infinite: true,
    speed: 500,
    className: 'slideBenefits mb-0 mb-md-5'
  }

  const {days} = useSelector(state => state.landing)

  

  return (
    <>
      <div className="headerPlan mb-4">
        <img src={imgPlan} alt="Landingpage" className='img-fluid'/>
        <h4 className="m-0 text-aqua text-bold">Tienda - ecommerce</h4>
      </div>  
      <div className="col-md-5">
        <img src={bannerLanding} alt="Landingpage" className='img-fluid w-100 mb-3'/>
        <div className="row mb-3">
          <div className="col-6 text-center">
            <small className="text-aqua">Tiempo estimado</small>
            <p className="m-0">{days} días hábiles</p>
          </div>
          <div className="col-6 text-center">
            <small className="text-aqua">Abono</small>
            <p className="m-0">50% inicial - 50% en la entrega</p>
          </div>
        </div>
        <Slider {...settings}>
          <div className="listInclude">
            <span className="text-aqua">Tu tienda 24/7</span>
          </div>
          <div className="listInclude">
            <span className="text-aqua">Amplio alcance</span>
          </div>
          <div className="listInclude">
            <span className="text-aqua">Seguridad transaccional</span>
          </div>
          <div className="listInclude">
            <span className="text-aqua">Rastreo de procesos</span>
          </div>
        </Slider>
        <Payment />
      </div>
      <div className="col-md-7">
        <div className="row justify-content-center">
          <div className="col-md-10 pt-5 pt-md-3">
            <p>Vender en pijama nunca fue tan chic. 🛍️ Con nuestra Tienda Virtual desde $999, creamos el universo perfecto para que tus productos se vendan solos. Diseño atractivo, funcional y, lo más importante, ¡que hace 'ka-ching'!</p>
            <div className="text-aqua mb-3">¿Qué ofrecemos?</div>
            <ul className='listPlans'>
              <li>Hosting + dominio .com por un año</li>
              <li>Certificado SSL (https)</li>
              <li>20 Cuentas de correo corporativo</li>
              <li>20 GB de espacio</li>
              <li>Link de redes sociales</li>
              <li>Botón flotante de WhatsApp</li>
              <li>Administrador de sitios web Wordpress + Elementor Pro</li>
              <li>Tutoría vía Zoom de 40 minutos  para la gestión de contenido de tu sitio</li>
              <li>Productos/Servicios (Inscripción de 5 productos en la entrega) -  Nosotros - Términos y condiciones - Políticas de privacidad - Carrito - Resúmen de pedido - Checkout - Pasarelas de pago - Contacto - Página de agradecimiento - Error 404 - Página de búsqueda</li>
            </ul>
            <div className="text-main my-3">¿Algo adicional?</div>
            <CheckValue
              id='S1'
              text='Galería de fotos'
              price={20}
              day={1}
            />
            <CheckValue
              id='S2'
              text='Blog (Subimos 1 publicación como modelo)'
              price={50}
              day={2}
            />
            <CheckValue
              id='S3'
              text='Brochure digital de 6 páginas enlazado a tu sitio'
              price={60}
              day={2}
            />
            <CheckValue
              id='S4'
              text='Plataforma de Email Marketing (hasta 1.000 envíos por mes) + tutoría de 30 minutos'
              price={80}
              day={2}
            />
            <CheckValue
              id='S5'
              text='Página adicional de Noticias o Novedades'
              price={50}
              day={1}
            />
            <CheckValue
              id='S6'
              text='Creación de plantilla personalizada para correos desde la página de contacto'
              price={30}
              day={1}
            />
            <FooterPlans />
            </div>
        </div>
      </div>
    </>
  )
}

export default PlanStore