import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/effect-creative'
import { EffectCreative } from 'swiper/modules'
//img
import proyect1 from '../img/brand/project_1.jpg'
import proyect2 from '../img/brand/project_2.jpg'
import proyect3 from '../img/brand/project_3.jpg'
import proyect4 from '../img/brand/project_4.jpg'
import proyect5 from '../img/brand/project_5.jpg'
import proyect6 from '../img/brand/project_6.jpg'
import proyect7 from '../img/brand/project_7.jpg'

const data = [
  {
    id:111,
    title:'Tony Store',
    description:'Tienda de regalos - mugs personalizados',
    img: proyect1,
    link:'https://tonystore2024.web.app/',
    number: '01',
    active: true
  },
  {
    id:222,
    title:'Play Móvil',
    description:'Operador de telefonía celular',
    img: proyect2,
    link:'https://playmovil.co/',
    number: '02',
    active: false
  },
  {
    id:333,
    title:'Novaartis SAS',
    description:'Arquitectura y obra civil',
    img: proyect3,
    link:'https://novaartissas.com/proyectos/',
    number: '03',
    active: false
  },
  {
    id:444,
    title:'Click Móvil',
    description:'Prestadora de servicios de internet',
    img: proyect4,
    link:'http://clickmovil.com.co/',
    number: '04',
    active: false
  },
  {
    id:555,
    title:'Coldinsqui',
    description:'Colegio Colombiano de Instrumentación Quirúrgica',
    img: proyect5,
    link:'https://www.coldinsqui.org/',
    number: '05',
    active: false
  },
  {
    id:666,
    title:'Karencarrenio.',
    description:'Hábitos de crecimiento personal y autoconocimiento',
    img: proyect6,
    link:'https://www.karencarrenio.com/',
    number: '06',
    active: false
  },
  {
    id:777,
    title:'Univermind',
    description:'Plataforma de cursos online',
    img: proyect7,
    link:'https://www.univermind.com/',
    number: '07',
    active: false
  },
]

const SlidePortfolio = () => {
  
  

  return (
    <>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        className='slidePortfolio'
        navigation={true}
        modules={[EffectCreative, Navigation]}
        grabCursor={true}
        effect={'creative'}
        creativeEffect={{
          prev: {
            shadow: true,
            origin: 'left center',
            translate: ['-5%', 0, -200],
            rotate: [0, 100, 0],
          },
          next: {
            origin: 'right center',
            translate: ['5%', 0, -200],
            rotate: [0, -100, 0],
          },
        }}
      >
        {
          data.map(el =>(
            <SwiperSlide key={el.id}>
              <div className='px-2 bg-dark' >
                <div className="row justify-content-center" >
                  <div className="col-md-10">
                    <div className="row">
                      <div className="col-md-7">
                        <div className="row">
                          <div className="col-5">
                            <img src={el.img} alt={el.title} className='img-fluid viewProject'/>
                          </div>
                          <div className="col-7 align-self-start align-self-md-end text-end info">
                            <h5>{el.title}</h5>
                            <p>{el.description}</p>
                            <a href={el.link} className='btn-line' rel='noreferrer noopener' target='_blank'>VER PROYECTO</a>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="number"><span className='text-line-aqua'>{el.number}</span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))
        }
        
      </Swiper>
      
    </>
  )
}

export default SlidePortfolio