import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { evalAmount } from '../redux/slices/plans';

const CheckValue = ({text,price, id, day}) => {

  const [thSelect, setThSelect] = useState(false)
  const dispatch = useDispatch()
  const {includes} = useSelector(state => state.landing)
  
  const evalExistence = ()=>{
    if(includes.find(el => el.id === id)){
      setThSelect(true)
    }else{
      setThSelect(false)
    }
  }

  useEffect(()=>evalExistence(),[includes])


  function formatCurrencyCOP(number) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });

    return formatter.format(number)
  }

  const handleSelectService = ()=>{
    setThSelect(!thSelect)
    dispatch(evalAmount(text,price,id,!thSelect,day))
  }

  return (
    <>
      <label htmlFor={id}  className={`inputCheck ${thSelect ? 'active': ''}`}>
        <input
          data-day={day || 0}
          defaultValue={price}
          type="checkbox"
          id={id}
          checked={thSelect}
          onChange={handleSelectService}
        />
        <span>{text || 'N/A'} <strong>+ {formatCurrencyCOP(price) || 0} USD</strong></span>
      </label>
    </>
  )
}

export default CheckValue