import React from 'react'
import payment from '../img/vector/payment.svg'

const Payment = () => {
  return (
    <>
      <div className="mt-5">
        <div className="row justify-content-center">
          <div className="col-md-9 text-center">
            <img src={payment} alt="" className='img-fluid mb-4'/>
            <p>Aceptamos tarjetas Crédito y Débito</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Payment