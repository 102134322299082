import React, { useEffect, useState } from 'react'
import CardServices from './CardServices'
import SlideHero from './SlideHero'
import { gsap } from 'gsap'
import Typewriter from 'typewriter-effect'
//img
import experience from '../img/vector/experience.svg'
import icoLanding from '../img/vector/icon_landing.svg'
import icoCorporative from '../img/vector/icon_corporative.svg'
import iconEcommerce from '../img/vector/icon_ecommerce.svg'
import iconDeveloper from '../img/vector/icon_developer.svg'
import iconPortfolio from '../img/vector/icon_portfolio.svg'
import {initAmount, setNamePlan, setSwitchSlice, setTypeService} from '../redux/slices/plans.js'
import { useDispatch } from'react-redux'

const Hero = () => {

  const [startEffect, setStartEffect] = useState(false)
  const dispatch = useDispatch()
  

  const handleLanding = (type, name)=> {
    dispatch(setSwitchSlice(true))
    dispatch(setTypeService(type))
    dispatch(setNamePlan(name))
    dispatch(initAmount(type))
  }

  const tl = gsap.timeline()

  useEffect(()=>{
    tl.to('.h-anim',{opacity:1, duration: 1.4, y: 0, delay: 1.2, stagger: '.3'})
      .to('.experience',{scale: 1, opacity: 1, duration: .3},'-=1.5')
  })

  useEffect(()=>{setTimeout(()=>{setStartEffect(true)},2000)})



  return (
    <>
      <section className='py-3 py-md-5 my-0 my-md-5' id='hero'>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 className='d-none d-md-block principalTitle'>
                <span className='text-line-aqua display-3 text-bold h-anim'>BIENVENIDO</span>
                <br />
                <span className='text-bold h-anim'>AL FUTURO DEL</span>
                <br />
                <span className='labelAqua text-bold h-anim'>DESARROLLO WEB</span>
              </h1>
              <div className="row justify-content-center mt-0 mt-md-5 pt-0 pt-md-4">
                <div className="col-md-12 col-11">
                  <div className="row">
                    <div className="col-4">
                      <img src={experience} alt="experiencia"  className='img-fluid experience'/>
                    </div>
                    <div className="col-8 text-end mt-4 mt-md-0">
                    <Typewriter
                      options={{
                        strings: ['Desarrollamos sitios web que no solo lucen geniales, sino que piensan por ti. Con AIWeb, tu página no es solo un sitio en Internet, es una experiencia interactiva.'],
                        autoStart: startEffect,
                        loop: false,
                        delay: 55,
                        deleteSpeed: 10,
                        pauseFor: 1000000000
                      }}
                    />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-center order-first order-md-last d-flex align-items-center flex-column">
              <div className="col-11 d-block d-md-none">
                <h1 className='text-center d-block d-md-none mb-4 mt-3  principalTitle'>
                  <span className='text-line-aqua display-3 text-bold h-anim'>LA MAGIA</span>
                  <br />
                  <span className='text-bold h-anim'>DE SER VISIBLE</span>
                  <br />
                  <span className='labelAqua text-bold h-anim'>EN LA WEB</span>
                </h1>
              </div>
              <SlideHero />
            </div>
            
          </div>
          <div className="row">
          <div className="col-md-12 mt-0 mt-md-5 pt-3 pt-md-5">
              <div className="row justify-content-center">
                <div className="col-md-12 col-11 text-center" id='planweb'>
                  <p className="text-center mb-4 mb-md-5 mt-3 mt-md-0 text-aqua">¿Qué tipo de proyecto quieres cotizar?</p>
                </div>
                <div className="col-md-6 col-lg-5 col-11">
                  <CardServices
                    title='IMPLEMENTACIÓN AI'
                    price='ASESORÍA'
                    from='ofrecemos'
                    onclick={(e)=> handleLanding(3,'IMPLEMENTACIÓN AI')}
                    image={iconPortfolio}
                  />
                </div>
                <div className="col-md-6 col-lg-5 col-11">
                  <CardServices
                    title='GESTIÓN DE CONTENIDO'
                    from='desde'
                    price='99 USD'
                    onclick={(e)=> handleLanding(5,'GESTIÓN DE CONTENIDO')}
                    image={iconPortfolio}
                  />
                </div>
                <div className="col-md-6 col-lg-5 col-11">
                  <CardServices
                    title='LANDING PAGE'
                    from='desde'
                    price='299 USD'
                    onclick={(e)=> handleLanding(0,'LANDING PAGE')}
                    image={icoLanding}
                  />
                </div>
                <div className="col-md-6 col-lg-5 col-11">
                  <CardServices
                    title='CORPORATIVA'
                    from='desde'
                    price='599 USD'
                    onclick={(e)=> handleLanding(1,'CORPORATIVA')}
                    image={icoCorporative}
                  />
                </div>
                <div className="col-md-6 col-lg-5 col-11">
                  <CardServices
                    title='TIENDA VIRTUAL'
                    from='desde'
                    price='999 USD'
                    onclick={(e)=> handleLanding(2,'TIENDA VIRTUAL')}
                    image={iconEcommerce}
                  />
                </div>
                
                <div className="col-md-6 col-lg-5 col-11">
                  <CardServices
                    title='APLICACIÓN WEB'
                    from='precio'
                    price='A CONVENIR'
                    onclick={(e)=> handleLanding(4,'APLICACIÓN WEB')}
                    image={iconDeveloper}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Hero