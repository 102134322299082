import React from 'react'
import { useSelector } from 'react-redux'
import MainForm from '../MainForm'
import FooterPlans from '../FooterPlans'
import PlanContent from './PlanContent'

const ContentBuild = ({handleClose}) => {

  const { isForm } = useSelector((state) => state.landing)

  return (
    <>
      <div className="p-3 p-md-5">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-4">
              <button onClick={handleClose} className="backPage">
                Volver al home
              </button>
            </div>
            {isForm ? <MainForm /> : <PlanContent />}
            {isForm && <FooterPlans />}
          </div>
        </div>
      </div>
    </>
  )
}

export default ContentBuild