import React from 'react'
import imgPlan from '../../img/vector/icon_portfolio.svg'
import bannerLanding from '../../img/brand/banner_portfolio.png'
import Slider from 'react-slick'
import Payment from '../Payment'

const PlanImplementation = () => {

  const settings = {
    dots: true,
    arrows: false,
    slidesToShow: 1,
    autoplay: true,
    infinite: true,
    speed: 500,
    className: 'slideBenefits mb-0 mb-md-5'
  }

  

  

  return (
    <>
      <div className="headerPlan mb-4">
        <img src={imgPlan} alt="Landingpage" className='img-fluid'/>
        <h4 className="m-0 text-aqua text-bold">OPTIMIZACIÓN DE FUNCIONES EMPRESARIALES CON AI</h4>
      </div>  
      <div className="col-md-5">
        <img src={bannerLanding} alt="Landingpage" className='img-fluid w-100 mb-3'/>
        <div className="row mb-3">
          <div className="col-6 text-center">
            <small className="text-aqua">Tiempo estimado</small>
            <p className="m-0">A convenir</p>
          </div>
          <div className="col-6 text-center">
            <small className="text-aqua">Abono</small>
            <p className="m-0">Único pago</p>
          </div>
        </div>
        <Slider {...settings}>
          <div className="listInclude">
            <span className="text-aqua">Exhibición profesional</span>
          </div>
          <div className="listInclude">
            <span className="text-aqua">Marca personal</span>
          </div>
          <div className="listInclude">
            <span className="text-aqua">Acceso gobal</span>
          </div>
          <div className="listInclude">
            <span className="text-aqua">Posiciona tu marca</span>
          </div>
        </Slider>
        <Payment />
      </div>
      <div className="col-md-7">
        <div className="row justify-content-center">
          <div className="col-md-10 pt-5 pt-md-3">
            <div className="text-aqua mb-3">¿Listo para llevar tu negocio al siguiente nivel? </div>
            <p>Con nuestro servicio de asesoría en Optimización de Funciones Empresariales con AI, transformamos el 'tal vez' en 'definitivamente'. ¡Es hora de que tu empresa juegue en las grandes ligas con la inteligencia artificial como tu MVP!</p>            
            </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-6">
            <a href="tel:+573125599832" className='btn-line w-100'>Llámanos</a>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <a
              href="https://api.whatsapp.com/send?phone=573059260031&text=Hola!%20Estoy%20interesad@%20en%20un%20desarrollo%20web%20a%20medida"
              className='btnAqua'
              target='_blank'
              rel='noreferrer'
            >Escríbenos</a>
          </div>
        </div>
      </div>
    </>
  )
}

export default PlanImplementation

