import React from 'react'
import Faq from "../components/Faq"
import Footer from "../components/Footer"
import Header from "../components/Header"
import Hero from "../components/Hero"
import Matrix from "../components/Matrix"
import MoveLabel from "../components/MoveLabel"
import Portfolio from "../components/Portfolio"
import SlicePlans from "../plans/SlicePlans"

const HomePage = () => {
  return (
    <>
      <Matrix />
      <Header/>
      <Hero />
      <MoveLabel text='En AIWeb, siempre estamos buscando las herramientas de IA más cool y fáciles de integrar para potenciar las webs de nuestros clientes'/>
      <Portfolio />
      <MoveLabel text='¿Tienes referencia de una herramienta AI que quieres conocer? ¡La investigamos por ti!'/>
      <Faq />
      <Footer/>

      <SlicePlans />
    </>
  )
}

export default HomePage