import React from 'react'
import SlidePortfolio from './SlidePortfolio'
import Comments from './Comments'

const Portfolio = () => {
  return (
    <>
      <section className="py-5 bg-dark" id='portfolio'>
        <div className="container pt-0 pt-md-5 pb-2 pb-md-5">
          <div className="row justify-content-center">
            <div className="col-md-12 col-10 justify-content-center d-flex text-center text-md-start">
              <h2 className='subtitle'>PROYECTOS RECIENTES</h2>
            </div>
          </div>
          <div className="row justify-content-center mt-5">
            <div className="col-md-10 pt-0 pt-md-5 pb-5 pb-md-3">
              <SlidePortfolio />
            </div>
            <div className="col-md-8 col-11">
              <Comments />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Portfolio