import React from 'react'

const CardServices = ({onclick, title, price, from, image}) => {
  return (
    <>
      <button className="cardService" onClick={onclick || null}>
        <div className="lines">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="text-end">
          <h5>
            <span>{title || 'NA'}</span>
            <div className="line">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </h5>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="row">
              <div className="col-9">
                <p>{from || ''}</p>
                <div className="price">{price || '$ 0'}</div>
                <div className="btn-line-blue">VER DETALLES</div>

              </div>
              <div className="col-3 align-self-center ps-0">
                <img src={image || null} alt="img" className='img-fluid icon'/>
              </div>
            </div>
          </div>
        </div>
      </button>
    </>
  )
}

export default CardServices