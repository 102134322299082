import React, { useEffect } from 'react'
import aiweb from '../img/vector/logo_aiweb.svg'
import { gsap } from 'gsap'

const Header = () => {

  const tl = gsap.timeline()

  useEffect(()=>{
    tl.to('.headerMenu', {duration: .7, y: 0, delay: 1})
      .to('.headerMenu img', {duration: 1, opacity:1 },'-=.3')
  })

  return (
    <>
      <header className="headerMenu">
        <img src={aiweb} alt="Logo AI WEB" className='img-fluid'/>
      </header>
    </>
  )
}

export default Header