import React, { useEffect } from 'react'
import Slider from "react-slick"
import { gsap } from 'gsap'

//img
import man from '../img/brand/man.png'
import s1 from '../img/vector/s1.svg'
import s2 from '../img/vector/s2.svg'
import s3 from '../img/vector/s3.svg'
import s4 from '../img/vector/s4.svg'
import s5 from '../img/vector/s5.svg'
import s6 from '../img/vector/s6.svg'
import s7 from '../img/vector/s7.svg'
import s8 from '../img/vector/s8.svg'
import s9 from '../img/vector/s9.svg'
//-
import phone from '../img/brand/phone.png'
import s21 from '../img/vector/s2-1.svg'
import s22 from '../img/vector/s2-2.svg'
import s23 from '../img/vector/s2-3.svg'


const SlideHero = () => {

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 7000,
  }

  const tl = gsap.timeline()

  useEffect(() => {
    tl.to('.content-slide', {opacity:1, duration:1, scale:1 , delay:1.5})
      .to('.content-slide .mn', {opacity:1, duration:1 , stagger:0.3}, '-=0.8')
  })


  return (
    <>
      <div className="content-slide">
        <Slider {...settings}>
          <div className="slide2">
            <div className="rotate">
              <img src={s21} alt="s21" className='s21 mn'/>
              <img src={s22} alt="s22" className='s22 mn'/>
              <img src={s23} alt="s23" className='s23 mn'/>
            </div>
            <img src={phone} alt="phone" className='phone'/>
          </div>
          <div className="slide1">
            <img src={s1} alt="s1" className='s1 mn'/>
            <img src={s2} alt="s2" className='s2 mn'/>
            <img src={s3} alt="s3" className='s3 mn'/>
            <img src={s6} alt="s6" className='s6 mn'/>
            <img src={s7} alt="s7" className='s7 mn'/>
            <img src={s4} alt="s4" className='s4 mn'/>
            <img src={s5} alt="s5" className='s5 mn'/>
            <img src={s8} alt="s8" className='s8 mn'/>
            <img src={s9} alt="s8" className='s9 mn'/>
          </div>
        </Slider>
        <img src={man} alt="man hero"  className='img-fluid man'/>
      </div>
    </>
  )
}

export default SlideHero