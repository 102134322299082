import React from 'react'
//img
import imgPlan from '../../img/vector/icon_landing.svg'
import bannerLanding from '../../img/brand/banner_landing.png'
import Slider from 'react-slick'
import FooterPlans from '../FooterPlans'
import CheckValue from '../CheckValue'
import { useSelector } from 'react-redux'
import Payment from '../Payment'


const PlanLanding = () => {

  const settings = {
    dots: true,
    arrows: false,
    slidesToShow: 1,
    autoplay: true,
    infinite: true,
    speed: 500,
    className: 'slideBenefits mb-0 mb-md-5'
  }

  const {days} = useSelector(state => state.landing)

  

  return (
    <>
      <div className="headerPlan mb-4">
        <img src={imgPlan} alt="Landingpage" className='img-fluid'/>
        <h4 className="m-0 text-aqua text-bold">Landing Page</h4>
      </div>  
      <div className="col-md-5">
        <img src={bannerLanding} alt="Landingpage" className='img-fluid w-100 mb-3'/>
        <div className="row mb-3">
          <div className="col-6 text-center">
            <small className="text-aqua">Tiempo estimado</small>
            <p className="m-0">{days} días hábiles</p>
          </div>
          <div className="col-6 text-center">
            <small className="text-aqua">Abono</small>
            <p className="m-0">Único pago</p>
          </div>
        </div>
        <Slider {...settings}>
          <div className="listInclude">
            <span className="text-aqua">Conversión aumentada</span>
          </div>
          <div className="listInclude">
            <span className="text-aqua">Generación de Leads</span>
          </div>
          <div className="listInclude">
            <span className="text-aqua">Eliminación de Distracciones</span>
          </div>
          <div className="listInclude">
            <span className="text-aqua"> Aumento de Credibilidad </span>
          </div>
        </Slider>
        <Payment/>
      </div>
      <div className="col-md-7">
        <div className="row justify-content-center">
          <div className="col-md-10 pt-5 pt-md-3">
            <p>Captura la atención como un influencer en ascenso 🌟. Con nuestras Landing Pages desde $299, te diseñamos un escaparate digital que no solo luce increíble, sino que convierte visitantes en fans (y clientes).</p>
            <div className="text-aqua mb-3">¿Qué ofrecemos?</div>
            <ul className='listPlans'>
              <li>Hosting + dominio .com por un año</li>
              <li>Certificado SSL (https)</li>
              <li>500 GB ancho de banda</li>
              <li>10 Cuentas de correo corporativo</li>
              <li>Administrador de sitios web Wordpress + Elementor Pro</li>
              <li>Tutoría vía Zoom de 30 minutos  para la gestión de contenido de tu sitio</li>
            </ul>
            <div className="text-main my-3">¿Algo adicional?</div>
            <CheckValue
              id='L1'
              text='Página de agradecimiento'
              price={20}
              day={1}
            />
            <CheckValue
              id='L2'
              text='Brochure digital de 6 páginas enlazado a tu sitio'
              price={50}
              day={2}
            />
           
            <FooterPlans />
            </div>
        </div>
      </div>
    </>
  )
}

export default PlanLanding