import React, { useEffect, useRef } from "react";

const Matrix = () => {

  const matrixContent = useRef(null)

  const animationLetters = () => {
    // Initialising the canvas
    var canvas = document.querySelector("canvas"),
      ctx = canvas.getContext("2d");

    // Setting the width and height of the canvas
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    // Setting up the letters
    var letters =
      "QWERTYUIOPASDFGHJKLZXCVBNM010001001011011101010110101010110000000011111101101110111011000000110011100110010111000000010010101011111001";
    letters = letters.split("");

    // Setting up the columns
    var fontSize = 30,
      columns = canvas.width / fontSize;

    // Setting up the drops
    var drops = [];
    for (var i = 0; i < columns; i++) {
      drops[i] = 1;
    }

    // Setting up the draw function
    function draw() {
      ctx.fillStyle = "rgba(0, 0, 0, .1)";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      for (var i = 0; i < drops.length; i++) {
        var text = letters[Math.floor(Math.random() * letters.length)];
        ctx.fillStyle = "#629dc2";
        ctx.fillText(text, i * fontSize, drops[i] * fontSize);
        drops[i]++;
        if (drops[i] * fontSize > canvas.height && Math.random() > 0.95) {
          drops[i] = 0;
        }
      }
    }

    // Loop the animation
    setInterval(draw, 70);
  };

  useEffect(() => {
    animationLetters();
  })


  return (
    <>
      <div className="canvasMatrix">
        <canvas className="matrix " />
      </div>
    </>
  )
};

export default Matrix;
