import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetDays, setAmount, setIsForm, setNamePlan, setResetIncludes, setSwitchSlice } from '../redux/slices/plans'
import { gsap } from 'gsap'
import LandingBuild from './landingpage/LandingBuild'
import CorporativeBuild from './corporative/CorporativeBuild'
import StoreBuild from './store/StoreBuild'
import PortfolioBuild from './implementation/ImplementationBuild'
import DevelopBuild from './development/DevelopBuild'
import ContentBuild from './content/ContentBuild'

const SlicePlans = () => {

  const {active, type: typeSlide} = useSelector(state => state.landing)
  const dispatch = useDispatch()
  const tl = gsap.timeline()

  const handleClose = () => {
    dispatch(setSwitchSlice(false))
    dispatch(setIsForm(false))
    dispatch(setAmount(0))
    dispatch(resetDays())
    dispatch(setResetIncludes([]))
    dispatch(setNamePlan(null))
  }

  const slideIn = () => {
    tl.to('.contentPlan .slices div',{x: 0, duration: .4, stagger:.2})
      .to('.contentPlan .content',{opacity: 1, duration: .6})
    }
    const slideOut = () => {
      tl.to('.contentPlan .content',{opacity: 0, duration: .4})
        .to('.contentPlan .slices div',{x: '100%', duration: .4, stagger:.2})
  }

  useEffect(() => {
    active? slideIn() : slideOut()
  }, [active])


  const RenderTypeSlide = (typeSlide) =>{
    switch (true) {
      case typeSlide === 0:
        return <LandingBuild handleClose={handleClose}/>
      case typeSlide === 1:
        return <CorporativeBuild handleClose={handleClose} />
      case typeSlide === 2:
        return <StoreBuild handleClose={handleClose} />
      case typeSlide === 3:
        return <PortfolioBuild handleClose={handleClose} />
      case typeSlide === 4:
        return <DevelopBuild handleClose={handleClose} />
      case typeSlide === 5:
        return <ContentBuild handleClose={handleClose} />
      default:
        return <LandingBuild handleClose={handleClose} />
    }
  }


  return (
    <>
      <div className={`contentPlan ${active ? 'active' : ''}`}>
        <div className="slices">
          <div />
          <div />
          <div />
          <div />
        </div>
        <div className="content">
          {
            RenderTypeSlide(typeSlide)
          }
        </div>
      </div>
    </>
  )
}

export default SlicePlans