import React from 'react'
import Slider from 'react-slick'
//img
import headerComment from '../img/vector/comments.svg'

const Comments = () => {


  const data = [
    {
      name: 'Narda Delgado', position: 'Presidenta COLDINSQUI',
      comment:'Excelente servicio, apoyo en diseño publicitario. Entregas puntuales y asesoría de acuerdo a nuestras necesidades como organización'
    },
    {
      name: 'Alejandro Medina', position: ' Gerente NOVAARTIS SAS',
      comment:'Se realizo un  diseño de acuerdo a nuestras necesidades y el resultado cumplió con nuestras expectativas, se manejo una excelente comunicación e interpretación de nuestra vision, la cual vemos reflejada en un resultado satisfactorio.'
    },
    {
      name: 'Karen Carreño', position: 'Lider de desarrollo',
      comment:'Recomiendo 100% su trabajo por su responsabilidad, interés, gran talento y pasión por lo que hace, eso hace que su trabajo destaque entre este gran mercado.'
    },
    {
      name: 'Julian Villamil', position: 'Ingeniero Industrial',
      comment:'Me encantó el enfoque minimalista para crear soluciones que mejoren la experiencia de usuario.'
    },
  ]

  const config = {
    dots: true,
    slidesToShow: 1,
    arrows: false,
    className: 'slideComments',
    autoplay: true,
    speed: 500
  }


  return (
    <>
      <div className="pt-0 pt-md-5 mt-5 pb-0 mb-md-5">
        <div className="commentHeader">
          <img src={headerComment} alt="header" className=" img-fluid" />
          <span>¿Que piensan nuestros clientes?</span>
        </div>
        <Slider {...config} >
          {
            data.map(el =>(
              <div key={el.name}>
                <div className="row">
                  <div className="col-md-4">
                    <h5 className="text-aqua">{el.name}</h5>
                    <span>{el.position}</span>
                  </div>
                  <div className="col-md-8 align-self-end">
                    <p>{el.comment}</p>
                  </div>
                </div>
              </div>
            ))
          }
        </Slider>
      </div>
    </>
  )
}

export default Comments