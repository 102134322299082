import React from 'react'
import Confetti from 'react-confetti'

const SendEmail = () => {

  const handleReloadClick = () => {
    window.location.reload()
  }

  return (
    <>
      <section className="sendEmail">
        <div className="modalThanks">
          <h2 className='principalTitle mb-0'>
            <span className='text-line-aqua display-5 text-bold'>GRACIAS</span>
          </h2>
          <h5 className="text-aqua text-bold">por tu confianza</h5>
          <p className='my-4'>Pronto estaremos respondiendo tu solicitud :)</p>
          <button className='btn-line' onClick={handleReloadClick}>VOLVER AL INICIO</button>
          <Confetti />
        </div>
      </section>
    </>
  )
}

export default SendEmail