import React from 'react'
import Slider from 'react-slick'
import imgPlan from '../../img/vector/icon_developer.svg'
import bannerLanding from '../../img/brand/banner_develop.png'
import Payment from '../Payment'

const PlanDevelop = () => {
  const settings = {
    dots: true,
    arrows: false,
    slidesToShow: 1,
    autoplay: true,
    infinite: true,
    speed: 500,
    className: 'slideBenefits mb-0 mb-md-5'
  }
  

  return (
    <>
      <div className="headerPlan mb-4">
        <img src={imgPlan} alt="Landingpage" className='img-fluid'/>
        <h4 className="m-0 text-aqua text-bold">Desarrollo</h4>
      </div>  
      <div className="col-md-5">
        <img src={bannerLanding} alt="Landingpage" className='img-fluid w-100 mb-3'/>
        <div className="row mb-3">
          <div className="col-6 text-center">
            <small className="text-aqua">Tiempo estimado</small>
            <p className="m-0">Según requerimientos</p>
          </div>
          <div className="col-6 text-center">
            <small className="text-aqua">Abono</small>
            <p className="m-0">A convenir</p>
          </div>
        </div>
        <Slider {...settings}>
          <div className="listInclude">
            <span className="text-aqua">Soluciones a medida</span>
          </div>
          <div className="listInclude">
            <span className="text-aqua">Eficiencia mejorada</span>
          </div>
          <div className="listInclude">
            <span className="text-aqua">Funcionalidad avanzada</span>
          </div>
          <div className="listInclude">
            <span className="text-aqua">Diseño exclusivo</span>
          </div>
          <div className="listInclude">
            <span className="text-aqua">Escalabilidad</span>
          </div>
        </Slider>
        <Payment />
      </div>
      <div className="col-md-7">
        <div className="row justify-content-center">
          <div className="col-md-10 pt-5 pt-md-3">
            <p>Las mejores historias son aquellas a medida, ¿cierto? 📖 Con nuestra Aplicación Web personalizada, construimos el 'traje' perfecto para tu negocio online. Tú sueñas, nosotros programamos. Hablemos y hagamos magia digital juntos.</p>
            <div className="text-aqua mb-3">Ventajas de tu desarrollo a medida</div>
            <ul className='listPlans'>
              <li>Plan de consumo bajo demanda</li>
              <li>Concurrencia ilimitada</li>
              <li>Diseño previo al desarrollo</li>
              <li>Automatización de procesos repetitivos o recurrentes</li>
              <li>Almacenamiento en base de datos de tiempo real</li>
              <li>Optimización de hasta 10 veces la carga de tu aplicación web</li>
              <li>Administrador personalizado de tu aplicación</li>
              <li>Escalabilidad de tu proyecto</li>
              <li>Testeo de rendimiento y lógica de negocio riguroso</li>
              <li>Envío de correos electrónicos multipropósito</li>
              <li>Reportes y notificaciones</li>
              <li>Firmas digitales</li>
              <li>Consumo de APIs</li>
            </ul>
            <div className="row">
              <div className="col-md-6">
                <a href="tel:+573125599832" className='btn-line w-100'>Llámanos</a>
              </div>
              <div className="col-md-6 mt-4 mt-md-0">
                <a
                  href="https://api.whatsapp.com/send?phone=573059260031&text=Hola!%20Estoy%20interesad@%20en%20un%20desarrollo%20web%20a%20medida"
                  className='btnAqua'
                  target='_blank'
                  rel='noreferrer'
                >Escríbenos</a>
              </div>
            </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default PlanDevelop