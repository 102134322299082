import React from "react";

const Faq = () => {
  const faqs = [
    {
      id: 1,
      title: "¿Qué métodos de pago acepta AIWeb?",
      answer: "¿Listo para iniciar tu aventura digital con nosotros pero te preguntas cómo pagar? ¡No hay problema! En AIWeb aceptamos una amplia gama de opciones: desde canales descentralizados como Binance, opciones internacionales y bancarizadas como Payoneer, PayPal y WesternUnion, hasta tarjetas de débito/crédito. ¿Estás en Colombia? Perfecto, también aceptamos pagos bancarizados locales como PSE, Bancolombia y Davivienda. ¡Tú eliges cómo quieres hacerlo!",
    },
    {
      id: 2,
      title: "¿Cómo se manejan los tiempos de entrega en AIWeb?",
      answer: "¿Ansioso por ver tu proyecto cobrar vida? Entendemos esa emoción. Al cotizar cualquiera de nuestros 6 servicios, te proporcionaremos los tiempos estimados para la finalización de tu proyecto. Recuerda, en AIWeb nos movemos al ritmo de los días hábiles, no de los días calendario, asegurando eficiencia y calidad en cada paso.",
    },
    {
      id: 3,
      title: "¿Quién cubre los gastos de operación para proyectos complejos?",
      answer: "¿Tienes un proyecto grandioso en mente que requiere APIs externas, bases de datos en tiempo real y más? En AIWeb, nos encargamos de darle forma a tus ideas más ambiciosas. Para estos proyectos robustos, los gastos de operación, testeo y uso son cubiertos por el cliente. Pero tranquilo, somos expertos en optimizar recursos. Contamos con un ambiente de pruebas que minimiza costos en las etapas iniciales, permitiéndote definir la lógica de negocio sin preocupaciones financieras excesivas.",
    },
    {
      id: 6,
      title: "¿Que gastos por parte de AI WEB cubre un desarrollo a medida?",
      answer: "Para proyectos robustos que consumen servicios de APIs externas de pago, bases de datos en tiempo real, microservicios, gestión de correos y notificaciones, estarán vinculadas a una tarjeta de crédito que el cliente habrá habilitado desde el inicio, los gastos de operación y testeo corren por cuenta del cliente. Nos preocupamos por la optimización de todos los recursos, por lo que contamos con un ambiente de pruebas favorable para las primeras etapas del desarrollo que implica gastos mínimos mientras se define toda la lógica de negocio.",
    },
  ];

  return (
    <>
      <section className="py-5">
        <div className="container py-0 py-md-5">
          <div className="row justify-content-center">
            <div className="col-md-6 col-10">
              <div className="d-flex justify-content-center">
                <h2 className="subtitle text-center">PREGUNTAS FRECUENTES</h2>
              </div>

              <div className="accordion mt-5" id="accordionFaqs">
                {faqs.map((el) => (
                    <div className="accordion-item" key={el.title}>
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapseF${el.id}`}
                        aria-expanded="false"
                        aria-controls={`collapseF${el.id}`}
                      >
                        {el.title}
                      </button>
                      <div
                        id={`collapseF${el.id}`}
                        className="accordion-collapse collapse"
                        aria-labelledby="headingF"
                        data-bs-parent="#accordionFaqs"
                      >
                        <div className="accordion-body">
                          <p className="m-0">{el.answer}</p>
                        </div>
                      </div>
                    </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faq;
